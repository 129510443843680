<template lang="pug">
  div
    consultantList

</template>

<script type="text/javascript">
import consultantList from '@/components/vip/consultantList';

export default {
  name: 'Consultants',

  components: {
    consultantList,
  },

  data() {
    return {

    };
  },

  computed: {},

  watch: {},

  created() {

  },
};
</script>

<style lang="scss">
@import './consultants';
@import '@/styles/transition';
</style>
