var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "are-consultant-card"
  }, [_c('div', {
    staticClass: "row align-items-center mx-0"
  }, [_c('div', {
    staticClass: "col-12 col-md-3 d-flex justify-content-center"
  }, [_c('div', {
    staticClass: "blk-consultant-picture"
  }, [_c('div', {
    staticClass: "panel-consultant-pictrue"
  }, [_c('img', {
    staticClass: "img-consultant-pictrue",
    attrs: {
      "src": _vm.item.profile.picture,
      "alt": _vm.item.profile.english_name,
      "title": _vm.item.profile.english_name
    }
  })])])]), _c('div', {
    staticClass: "col-12 col-md-9 pb-4"
  }, [_c('div', {
    staticClass: "blk-consultant-info"
  }, [_c('h2', {
    staticClass: "h2-consultant-name"
  }, [_vm._v(_vm._s(_vm.item.profile.english_name))]), _c('p', {
    staticClass: "txt-consultant-nationality"
  }, [_c('span', {
    staticClass: "txt-consultant-title"
  }, [_vm._v(_vm._s(_vm.$t('nationality')))]), _c('span', [_vm._v(_vm._s(_vm.item.profile.nationality))])]), _c('p', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.item.score > 60,
      expression: "item.score > 60"
    }],
    staticClass: "txt-consultant-score"
  }, [_c('span', {
    staticClass: "txt-consultant-title"
  }, [_vm._v(_vm._s(_vm.$t('teacherScore')))]), _c('span', [_vm._v(_vm._s(_vm.item.score.final_avg))])]), _c('p', {
    staticClass: "txt-consultant-introduction"
  }, [_vm._v(_vm._s(_vm.item.profile.self_introduction))]), _c('a', {
    staticClass: "btn lnk-consutant-more",
    attrs: {
      "href": `/dojo/consultants/${_vm.item.profile.hb_consultant_id}`
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onRedirectProfile(_vm.item.profile.hb_consultant_id);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('more')))])])])]), _c('div', {
    staticClass: "heart-box"
  }, [_vm.consultantLike === _vm.consultantsListConstant.CONSULTANT_LIKE ? _c('div', {
    on: {
      "click": function ($event) {
        return _vm.editLike(_vm.item.profile.hb_consultant_id, false);
      }
    }
  }, [_c('font-awesome-icon', {
    staticClass: "like-icon",
    attrs: {
      "icon": "heart"
    }
  })], 1) : _c('div', {
    on: {
      "click": function ($event) {
        return _vm.editLike(_vm.item.profile.hb_consultant_id, true);
      }
    }
  }, [_c('font-awesome-icon', {
    staticClass: "unlike-icon",
    attrs: {
      "icon": ['far', 'heart']
    }
  })], 1)]), _c('div', {
    staticClass: "reminders"
  }, [!!_vm.item.bindRemendData.status ? _c('div', {
    on: {
      "click": function ($event) {
        return _vm.closeVipRemindConsultant();
      }
    }
  }, [_c('font-awesome-icon', {
    staticClass: "reminders-icon",
    attrs: {
      "icon": "bell"
    }
  })], 1) : _c('div', {
    on: {
      "click": function ($event) {
        return _vm.openVipRemindConsultant();
      }
    }
  }, [_c('font-awesome-icon', {
    staticClass: "unreminders-icon",
    attrs: {
      "icon": "bell"
    }
  })], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }