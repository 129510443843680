<template lang="pug">
.reg-consultant-list
  .form
    .row.are-consultant-searchbar.text-center
      .col-12.col-md-4
        .form-group
          vue-multiselect.mb-2.mr-sm-2(
            v-model="filter.nationality_list",
            :placeholder="$t('nationality_option_first')",
            :options="nationalityList",
            :multiple="true",
            :close-on-select="false",
            :hide-selected="true",
            :preserve-search="true",
            :max-height="300"
          )
      //-  JR無適性
      //- .col-12.col-md-3
      //-   .form-group
      //-     select.form-control.mb-2.mr-sm-2(v-model='filter.interest_list')
      //-       option(value='0') {{ $t('interest_option_first') }}
      //-       option(v-for='item in interestList' :value='item.id' :key='item.id') {{ item.title }}
      .col-12.col-md-3
        .form-group
          input.form-control(
            type="text",
            v-model="query.name",
            placeholder="請輸入顧問關鍵字",
            @keyup.enter="getConsultantList(consultantsListConstant.GET_TYPE.SEARCH)"
          )
      .col-12.col-md-3.filter-btn.mt-1
        b-button.px-0.btn-search(
          variant="outline-primary",
          @click="getConsultantList(consultantsListConstant.GET_TYPE.SEARCH)"
        ) {{ $t('search') }}
        b-button.px-0.btn-like-consultant(
          v-if="showLike",
          @click="showLikeConsultant"
        ) {{ $t('like_consultant') }}
        b-button.px-0(
          v-else-if="!showLike",
          variant="secondary",
          @click="showAllConsultant"
        ) {{ $t('back') }}
  //- 查無顧問時
  p(style="color: red") 下方為願意接受一對一指定的顧問；其他顧問將會由系統自動媒合課程，未來上課會遇到的顧問將比列表中多更多喔！
  .text-center.no_consultant_list(
    v-if="(consultantList.data && consultantList.data.length === 0) || consultantAllData.length === 0"
  )
    span {{ $t('no_consultant_list') }}
  template(v-if="screenWidth > 767 && consultantAllData.length !== 0")
    .are-consultant
      template(v-for="(item, index) in consultantAllData")
        consultant-list-item(
          :item="{...item, bindRemendData: consultantListBindRemendData[item.profile.hb_consultant_id]}",
          :index="index",
          :showlikelist="showLike",
          :key="item.profile.hb_consultant_id",
          @cancelLike="cancelLike"
          @getVipRemindConsultant="getVipRemindConsultant"
        )
      b-pagination.windows_pagination(
        v-if="consultantList.total",
        align="center",
        :total-rows="consultantList.total",
        v-model="window_current_page",
        :per-page="Number(consultantList.per_page)",
        @input="changePageWithLoading()"
      )
  template(v-if="screenWidth <= 767 && consultantAllData.length !== 0")
    .are-consultant
      template(v-for="(item, index) in consultantAllData")
        consultant-list-item(
          v-show="index == mobile_current_page_index",
          :item="{...item, bindRemendData: consultantListBindRemendData[item.profile.hb_consultant_id]}",
          :index="index",
          :showlikelist="showLike",
          :key="item.profile.hb_consultant_id",
          @cancellike="cancelLike"
          @getVipRemindConsultant="getVipRemindConsultant"
        )
      b-button.lnk-consultant-previous(
        @click="mobilePrevPage()",
        :disabled="mobileChangePageDisable('prev')",
        :class="[mobileChangePageDisable('prev') ? 'lnk-consultant-disabled' : '']"
      )
        font-awesome-icon(icon="caret-left")
      b-button.lnk-consultant-next(
        @click="mobileNextPage()",
        :disabled="mobileChangePageDisable('next')",
        :class="[mobileChangePageDisable('next') ? 'lnk-consultant-disabled' : '']"
      )
        font-awesome-icon(icon="caret-right")
</template>

<script type="text/javascript">
import vueMultiselect from 'vue-multiselect';
import lioshutanApi from '@/api';
import lodash from 'lodash';
import moment from 'moment';
import consultantsListConstant from '@/constants/consultantsList.js';
import { ConsultantListLang } from '@/components/vip/consultantList/lang.js';
import consultantListItem from '@/components/vip/consultantList/item/index.vue';
import wuwowLoadingMixin from '@/components/wuwowLoading/mixin/index.vue';

// 此為國籍假資料，已作廢不使用，國籍目前由api取得(此條先註解保留起來，避免後續有其他意外比較好找bug)
// import nationality from '@/components/vip/consultantList/nationality.js';

export default {
  name: 'ConsultantList',

  components: {
    consultantListItem,
    vueMultiselect,
  },

  mixins: [wuwowLoadingMixin],

  i18n: ConsultantListLang,

  // beforeRouteEnter(to, from, next) {
  //   console.log('213213');
  //   console.log(to, from);
  //   next();
  // },

  data() {
    return {
      filter: {
        nationality_list: '',
        interest_list: 0,
        name: '',
      },
      query: {
        nationality_list: '',
        interest_list: 0,
        name: '',
      },
      window_current_page: this.getLocalStorage()?.page || 1,
      mobile_current_page_index: 0,
      per_page: 0,
      screenWidth: document.body.clientWidth,
      showLike: true,
      likeStatus: false,
      consultantAllData: [],
      likeConsultant: [],
      consultantsListConstant: consultantsListConstant,
      interestList: [],
      consultantList: [],
      consultantListBindRemendData: [],
      // 假資料
      // InterestList: consultants.interested_topics,
      // nationalityList: nationality, // 原先預設值為國籍假資料，假資料已作廢不使用，國籍目前由api取得(此條先註解保留起來，避免後續有其他意外比較好找bug)
      nationalityList: [],
    };
  },

  computed: {
    consultantIds() {
      if (!lodash.size(this.consultantAllData)) {
        return [];
      }
      return lodash.map(this.consultantAllData, (item) => item.profile.hb_consultant_id);
    },
  },

  watch: {},

  async mounted(){
    // TODO: 先暫時拿掉
    // this.queryUserIconData();
    const res = await lioshutanApi.common.getConvenientCountries({
      needPaginator: 0,
      isEnable: 1,
    });
    this.nationalityList = res.data.data.countries.map((item) => item.displayName);
    console.log(this.nationalityList);
    // this.nationalityList = [...new Set([...res.data.data.countries.map((item) => item.id), ...nationality])];
  },

  async created() {
    const tag = this.startLoading();
    this.per_page = (this.screenWidth > 767) ? 6 : 1000;

    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
      })();
    };
    // 先打完國籍資料，再打興趣資料，再打顧問資料
    await this.getCountriesList();
    // await lioshutanApi.personnel.queryNationalityList();
    await this.getInterestList();
    // this.interestList = await lioshutanApi.personnel.queryInterestList();
    await this.getConsultantList(consultantsListConstant.GET_TYPE.CHANG_PAGE);
    // await this.getVipRemindConsultant();
    this.endLoading(tag);
  },

  methods: {
    async getVipRemindConsultant() {
      const tag = this.startLoading();
      const res = await lioshutanApi.vip.getVipRemindConsultant({
        hbConsultantIds: [...this.consultantIds],
      });
      this.consultantListBindRemendData = res.data.data;
      this.endLoading(tag);
    },
    async getCountriesList() {
      const res = await lioshutanApi.common.getConvenientCountries({
        needPaginator: 0,
        isEnable: 1,
      });
      this.nationalityList = res.data.data.countries.map((item) => item.displayName);
    // this.nationalityList = [...new Set([...res.data.data.countries.map((item) => item.id), ...nationality])];
    },
    async getInterestList(){
      const response = await lioshutanApi.personnel.queryInterestList();
      this.interestList = response.data.data.interested_topics;
    },
    async getConsultantList(type){
      this.consultantAllData = [];
      const tag = this.startLoading();
      // TODO: 這裡的判斷有點怪，應該是用下面的寫法才對
      if (type !== consultantsListConstant.GET_TYPE.LIKE) {
        this.likeConsultant = [];
      }

      if (type === consultantsListConstant.GET_TYPE.SEARCH) {
        this.window_current_page = 1;
      }

      // TODO: 暫時先改成這樣
      if (this.likeStatus) {
        const params = {
          platform: consultantsListConstant.API_PARAM.PLAT_FORM_JR, // wuwow固定帶1
        };
        const response = await lioshutanApi.personnel.queryLikesConsultant(params);
        this.likeConsultant = response.data.data.likes;
      } else {
        this.likeConsultant = [];
      }

      // 先取得主要的顧問清單
      const params = this.setParamsToGetConsultantList();
      const response = await lioshutanApi.personnel.queryConsultantList(params);
      this.consultantList = {
        data: response.data.data.teach,
        ...response.data.meta.pagination,
      };
      // TODO: 先暫時拿掉
      // this.setConsultantQueryParams(params);
      // 用顧問清單的顧問ID去搜尋顧問分數或學生喜愛程度
      const consultantId = lodash.map(response.data.data.teach, 'profile.hb_consultant_id');
      const score = await this.getConsultantScore(consultantId);
      const favorable = await this.getConsultantFavorable(consultantId);
      // 將所有API的資料整理成一包
      this.setConsultantAllData(response, score, favorable);
      await this.getVipRemindConsultant();
      this.endLoading(tag);
      this.scrollToTeacher();
    },
    scrollToTeacher() {
      const hb_consultant_id = this.getLocalStorage()?.hb_consultant_id || '';
      if (!hb_consultant_id) {
        return;
      }
      const index = this.consultantList.data.findIndex((item) => item.profile.hb_consultant_id === hb_consultant_id);
      if (this.screenWidth <= 767) {
        this.mobile_current_page_index = index;
        return;
      }
      this.$nextTick(() => {
        const dom = document.querySelector(`.are-consultant-card:nth-child(${index + 1})`);
        dom.scrollIntoView({ behavior: 'smooth', block: 'end' });
      });
    },
    setLocalStorage(data) {
      window.localStorage.setItem('consultant', JSON.stringify(data));
    },
    getLocalStorage() {
      return JSON.parse(window.localStorage.getItem('consultant'));
    },
    setParamsToGetConsultantList(){
      // 設置取得顧問列表的參數
      const params = {
        interestedTopicIds: [],
        nationalityIds: [],
      };
      this.query.nationality_list = this.filter.nationality_list;
      this.query.interest_list = this.filter.interest_list;

      if (this.query.nationality_list !== '') {
        this.query.nationality_list.forEach((nationality) => {
          params.nationalityIds.push(nationality);
        });
        // params.nationalityIds.push(this.query.nationality_list);
      } else {
        params.nationalityIds = this.nationalityList;
      }

      if (this.query.interest_list !== 0) {
        params.interestedTopicIds.push(this.query.interest_list);
      } else {
        // 如果沒有篩選 就是全帶
        params.interestedTopicIds = lodash.map(this.interestList, 'id');
      }

      if (this.query.name) {
        params.keyword = this.query.name;
      }

      if (this.likeConsultant.length !== 0) {
        params.teacherIds = this.likeConsultant;
      }

      params.platform = consultantsListConstant.API_PARAM.PLAT_FORM_JR;
      params.perPage = 10;
      // TODO: 要加上去欸!
      if (this.screenWidth <= 767) {
        this.mobile_current_page_index = 0;
      }
      const hb_consultant_id = this.getLocalStorage('consultant')?.hb_consultant_id || '';
      this.setLocalStorage({ page: this.window_current_page, hb_consultant_id });
      params.page = this.window_current_page;
      return params;
    },
    async getConsultantScore(consultantId) {
      // 取得顧問分數 時間區間固定帶當月份
      const startTime = ' 00:00:00', endTime = ' 23:59:59';
      const startOfMonth = moment().startOf('month').format('YYYY-MM-DD') + startTime;
      const endOfMonth = moment().endOf('month').format('YYYY-MM-DD') + endTime;

      const params = {
        platform: consultantsListConstant.API_PARAM.PLAT_FORM_JR,
        type: consultantsListConstant.API_PARAM.TYPE_ORDER,
        teacherIds: consultantId,
        'rangeTimes[start_date]': startOfMonth,
        'rangeTimes[end_date]': endOfMonth,
      };
      const response = await lioshutanApi.personnel.queryConsultantScore(params);
      return response;
    },
    async getConsultantFavorable(consultantId) {
      const params = {
        platform: consultantsListConstant.API_PARAM.PLAT_FORM_JR,
        teacherIds: consultantId,
      };
      const response = await lioshutanApi.personnel.queryConsultantFavorable(params);
      return response;
    },
    // 將三支API取回來的資料 依顧問ID整理成同一包
    setConsultantAllData(list, score, favorable){
      // 主資料先塞進consultantAllData裡
      this.consultantAllData = list.data.data.teach;
      const scoreData = score.data.data.score;
      const favorableData = favorable.data.data.favorites;
      // 其他資料依顧問ID分類
      const scoreDataKeyBy = lodash.keyBy(scoreData, 'hb_consultant_id'),
            favorableDataKeyBy = lodash.keyBy(favorableData, 'teacher_id');

      // 依主資料(顧問資料)的顧問ID將分數/喜愛程度 整理至consultantAllData
      lodash.map(this.consultantAllData, (item) => {
        const key = item.profile.hb_consultant_id;
        item.favorable = favorableDataKeyBy[key] ? favorableDataKeyBy[key].favorable : '';
        item.score = scoreDataKeyBy[key] ? scoreDataKeyBy[key] : '';

        // 因為顧問的適性裡只有ID 所以從列表的API中用適性ID取得適性的中文
        item.topicTitle = [];
        lodash.map(item.interested_topic_ids, (topicId) => {
          const topic = lodash.find(this.interestList, { id: topicId });
          if (topic) {
            item.topicTitle.push(topic.title);
          }
        });
      });
      // TODO: 先暫時拿掉
      // this.setConsultantAllDataList(this.consultantAllData);
    },
    async changePageWithLoading() { // 點選頁面會觸發
      const tag = this.startLoading();
      window.scrollTo(0, 0);
      await this.getConsultantList(consultantsListConstant.GET_TYPE.CHANG_PAGE);
      this.endLoading(tag);
    },
    mobilePrevPage(){
      this.mobile_current_page_index -= 1;
      if (this.mobile_current_page_index === -1) {
        this.window_current_page -= 1;
        this.changePageWithLoading();
        this.mobile_current_page_index = this.consultantList.per_page - 1;
      }
    },
    mobileNextPage(){
      this.mobile_current_page_index += 1;
      if (this.mobile_current_page_index === this.consultantList.per_page) {
        this.window_current_page = this.window_current_page + 1;
        this.changePageWithLoading();
        this.mobile_current_page_index = 0;
      }
    },
    mobileChangePageDisable(type){
      // 最前面一筆或最後一筆按鈕就要disable
      const index = this.mobile_current_page_index;
      const page = this.window_current_page;
      if (type === 'prev' && index === 0 && page === 1) {
        return true;
      } else if (type === 'next' && (this.consultantList.from + index === this.consultantList.to) && (page === this.consultantList.last_page)) {
        return true;
      } else {
        return false;
      }
    },
    async showLikeConsultant(){
      this.showLike = false;
      this.likeStatus = true;
      this.reNewPage();
      const params = {
        platform: consultantsListConstant.API_PARAM.PLAT_FORM_JR, // wuwow固定帶1
      };

      // 呼叫api取得喜歡的顧問ID 用ID去查詢顧問清單
      const response = await lioshutanApi.personnel.queryLikesConsultant(params);
      this.likeConsultant = response.data.data.likes;
      if (this.likeConsultant.length !== 0) {
        this.getConsultantList(consultantsListConstant.GET_TYPE.LIKE);
      } else {
        this.consultantAllData = [];
      }
    },
    showAllConsultant(){
      this.showLike = true;
      this.likeStatus = false;
      this.getConsultantList(consultantsListConstant.GET_TYPE.ALL);
    },
    cancelLike(){
      this.showLikeConsultant();
    },

    reNewPage(){
      this.window_current_page = 1;
      this.mobile_current_page_index = 1;
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.form-control {
  height: 43px;
  border-radius: 0.25rem !important;
}
</style>
