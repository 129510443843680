const ConsultantListLang = {
  messages: {
    tw: {
      back: '返回',
      interest_option_first: '不分興趣主題',
      like_consultant: '喜愛',
      nationality_option_first: '所有國籍顧問',
      no_consultant_list: '查無符合顧問',
      search: '搜尋',
    },
    en: {
      back: 'Back',
      interest_option_first: 'All Interest Topic',
      like_consultant: 'Like',
      nationality_option_first: 'All Nationality',
      no_consultant_list: 'Can not found Consultant',
      search: 'Search',
    },
  },
};

export {
  ConsultantListLang
};
