const consultantListItemLang = {
  messages: {
    tw: {
      experience: '已授課 ',
      lessons: ' 堂',
      more: '了解更多',
    },
    en: {
      experience: 'Teaching Experience ',
      lessons: ' Lessons',
      more: 'More',
    },
  },
};

export {
  consultantListItemLang
};
