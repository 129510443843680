<template lang="pug">
  .are-consultant-card
    .row.align-items-center.mx-0
      .col-12.col-md-3.d-flex.justify-content-center
        .blk-consultant-picture
          .panel-consultant-pictrue
            img.img-consultant-pictrue(:src='item.profile.picture' :alt='item.profile.english_name' :title='item.profile.english_name')
      .col-12.col-md-9.pb-4
        .blk-consultant-info
          h2.h2-consultant-name {{ item.profile.english_name }}
          p.txt-consultant-nationality
            span.txt-consultant-title {{ $t('nationality') }}
            span {{ item.profile.nationality }}
          p.txt-consultant-score(v-show='item.score > 60')
            span.txt-consultant-title {{ $t('teacherScore') }}
            span {{ item.score.final_avg }}
          //- JR 不需要適性
          //- p.txt-consultant-topic
          //-   span.txt-consultant-title {{ $t('topic') }}
          //-   span
          //-     template(v-for='(topic, index) in item.topicTitle')
          //-       template(v-if='index != 0') ,&nbsp;
          //-       | {{ topic }}
          p.txt-consultant-introduction {{ item.profile.self_introduction }}
          //- // <p class="txt-consultant-suggestion">{{ item.suggestion }}</p>
          //- b-button.lnk-consutant-more(variant="outline-primary" @click='onRedirectProfile(item.profile.hb_consultant_id)') {{$t('more')}}
          a.btn.lnk-consutant-more(:href='`/dojo/consultants/${item.profile.hb_consultant_id}`' @click.prevent='onRedirectProfile(item.profile.hb_consultant_id)') {{ $t('more') }}
    .heart-box
      //- // 移除LIKE
      div(v-if='consultantLike === consultantsListConstant.CONSULTANT_LIKE' @click='editLike(item.profile.hb_consultant_id, false)')
        font-awesome-icon.like-icon(icon="heart")
      //- // 設定LIKE
      div(v-else @click='editLike(item.profile.hb_consultant_id, true)')
        font-awesome-icon.unlike-icon(:icon="['far', 'heart']")
    .reminders
      div(v-if="!!item.bindRemendData.status" @click="closeVipRemindConsultant()")
        font-awesome-icon.reminders-icon(icon="bell")
      div(v-else @click="openVipRemindConsultant()")
        font-awesome-icon.unreminders-icon(icon="bell")
</template>

<script>
import lioshutanApi from '@/api';
import { consultantListItemLang } from './lang';
import consultantsListConstant from '@/constants/consultantsList.js';
import { debounce } from 'lodash';
import swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
// import LearnRuleModal from '@/components/wushare/learnRuleModal';
export default {
  name: 'ConsultantListItem',

  components: {
    // LearnRuleModal,
  },

  emits: ['getVipRemindConsultant'],

  props: {
    item: {
      type: Object,
      default: function() {
        return {};
      },
      deep: true,
    },

    index: {
      type: Number,
      default: function() {
        return 0;
      },
    },

    showLikeList: {
      type: Boolean,
      default: function() {
        return false;
      },
    },
  },

  i18n: consultantListItemLang,

  data() {
    return {
      islikeConsultant: this.item.favorable,
      showLike: false,
      likeEditable: true,
      consultantLike: this.item.favorable,
      consultantsListConstant: consultantsListConstant,
      openVipRemindConsultant: debounce(this.createVipRemindConsultant, 300),
      closeVipRemindConsultant: debounce(this.delVipRemindConsultant, 300),
    };
  },

  computed: {},

  watch: {},

  created() {},

  mounted(){
    if (this.islikeConsultant === 1) {
      this.showlike = false;
    } else {
      this.showlike = true;
    }
  },

  methods: {
    async editLike(id, status) {
      this.showLike = status;
      // 設置不要讓使用者連點
      if (this.likeEditable) {
        this.clickTimer();

        const params = {
          hi: id,
        };

        // 判斷是移除喜愛還是加入喜愛
        if (status) {
          // 喜愛的話
          params.st = status ? 1 : 0;
          await lioshutanApi.personnel.addVipLikeConsultant(params);
          this.consultantLike = consultantsListConstant.CONSULTANT_LIKE;
        } else {
          await lioshutanApi.personnel.cancelVipLikeConsultant(params);
          this.consultantLike = '';
        }

        params.index = this.index;
        // TODO: 先暫時拿掉
        // this.setLikeStatus(params);
      } else {
        console.log('賣購矇阿辣!');
      }
    },
    onRedirectProfile(hb_consultant_id) {
      const data = JSON.parse(window.localStorage.getItem('consultant'));
      window.localStorage.setItem('consultant', JSON.stringify({
        ...data,
        hb_consultant_id,
      }));
      this.$router.push({
        name: 'ConsultantDetail',
        params: {
          id: hb_consultant_id,
        },
      });
    },
    async createVipRemindConsultant() {
      try {
        await lioshutanApi.vip.createVipRemindConsultant({
          hbConsultantId: [this.item.bindRemendData.hb_consultant_id],
        });
        this.$emit('getVipRemindConsultant');
        await swal.fire({
          title: '顧問授課時間通知設定成功',
          text: '您已設定此顧問新增授課時間通知，請確認是否已完成綁定課程通知(Line notify)，謝謝您',
          icon: 'success',
        });
      } catch (e) {
        console.log(e);
      }
    },
    async delVipRemindConsultant() {
      try {
        await lioshutanApi.vip.delVipRemindConsultant(this.item.bindRemendData.id);
        this.$emit('getVipRemindConsultant');
        // await swal.fire({
        //   title: '顧問授課時間不通知設定成功',
        //   text: '您已設定此顧問新增授課時間通知，請確認是否已完成綁定課程通知(Line notify)，謝謝您',
        //   icon: 'success',
        // });
      } catch (e) {
        console.log(e);
      }
    },
    // 三秒內不給設定
    clickTimer() {
      this.likeEditable = false;
      setTimeout(() => {
        this.likeEditable = true;
      }, 1000 * 3);
    },

  },
};
</script>
<style scoped>
.heart-box .like-icon{
  color: #dc3232;
}
.heart-box .unlike-icon{
  color: #d9d9d9;
}

.lnk-consutant-more:hover {
  background-color: #82c1ea;
}
</style>

